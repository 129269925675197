@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic');
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "../plugin/font-awesome/font-awesome";

@import "variables";
@import "../helpers";
@import "global";
//@import "../plugin/toastr/toastr.scss";
//@import "../plugin/sweetalert/sweetalert.scss";
//@import "pages/index";
//@import "wizard.scss";
//@import "pages/coaches";
//@import "pages/pronas";
//@import "pages/franchise";
//@import "pages/page-school";