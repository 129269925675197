// Header Styles
.logged-in-as {
  margin: 0;
  border-radius: 0;
}

// Navbar active state
.navbar-default .navbar-nav > li > a.active:focus,
.navbar-default .navbar-nav > li > a.active:hover,
.navbar-default .navbar-nav > li > a.active,
.navbar-default .dropdown-menu > li > a.active {
  color: #262626;
  background-color: #f5f5f5;
}

.navbar-header {
  line-height: 88px;
}

.nav-top, .nav-top-mobile {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    padding: 0 15px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .social-container {
    display: flex;
    > div {
      padding: 0 5px;
    }
  }
  .phone-container {
    a {
      color: #00a8ff;
      font-size: 17px;
    }
    img {
      vertical-align: top;
      margin-top: 3px;
    }
  }
}
.nav-top-mobile {
  display: none;
  justify-content: center;
  flex-direction: column;
  > div {
    padding: 5px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

#modalCallback .modal-body {
  text-align: left;
  .modal-form-group {
    margin-bottom: 20px;
  }
}

#app {
  min-height: 100%;
  height: 100%;
}

.global-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -157px;
  overflow: auto;
  padding-bottom: 207px;
  .navbar-default {
    &.navbar {
      background: #fff;
      border-color: #fff;
      box-shadow: 0 0 25px #e0e4e8;
      margin-bottom: 20px;
      padding: 10px 0 10px 0;
    }
    .navbar-nav > li > a {
      color: #000;
      font-weight: 600;
      font-size: 14px;
      line-height: 46px;
      padding: 0 15px;
    }
  }
  .navbar-default .navbar-nav > li > a.active:focus,
  .navbar-default .navbar-nav > li > a.active:hover,
  .navbar-default .navbar-nav > li > a.active,
  .navbar-default .dropdown-menu > li > a.active {
    background-color: transparent;
  }
}

#footer {
  background: #edf9ff;
  position: relative;
  margin-top: -157px;
  min-height: 157px;
  clear: both;
//  color: #fff;
  .content-section {
    > div {
      margin: 15px 0;
    }
  }
  .btn-callback-request {
    margin-top: 15px;
    width: 100%;
  }
}

.header-container > div {
  font-size: 12px;
}

.content-section {
  //padding: 98px 0;
  max-width: 1200px;
  margin: 0 auto;
}

body {
  font-size: 12px;
  font-family: $font-family-1;
}

.title-1 {
  //&::before {
  //  content: '';
  //  display: inline-block;
  //  border-left: 2px solid #00a8ff;
  //  height: 1em;
  //  margin-left: -20px;
  //  position: absolute;
  //  margin-top: 0px;
  //}
  color: #053c85;
  font-size: 3.33em;
  line-height: 1em;
  font-weight: lighter;
  padding-bottom: 20px;
}

.wb-logo-1 {
  width: 150px;
}

ol, ul {
  li {
    padding: 4px 0;
  }
}

.side-content {
  .side-banner-1 {
    left: -27px;
    position: relative;
  }
  .side-thin {
    width: 73%;
    margin: 0 auto;
  }
  .side-steps {
    margin-top: 7px;
    .steps-title {
      color: #053c85;
      font-size: 18px;
      text-align: center;
      margin-bottom: 12px;
    }
    .steps-container {
      .step {
        background: url("/img/content/franchise/step-icon.png") no-repeat left center;
        padding: 17px 0 19px 50px;
        + .step {
          border-top: 1px solid #f0f0f0;
        }
        .step-label {
          color: #afbfd2;
        }
        .step-name {
          color: #00a8ff;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .apply-button {
      margin-top: 33px;
    }
  }
  .side-stats {
    margin-top: 42px;
    .stat {
      + .stat {
        margin-top: 35px;
      }
      .stat-num {
        color: #053c85;
        font-size: 40px;
        line-height: 58px;
      }
      .stat-label {
        color: #35455a;
        font-size: 15px;
      }
    }
  }
  .side-banner-2-wrapper {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 10px;
  }
  .center-button-wrapper {
    width: 290px;
    margin: 0 auto;
  }
}
@media (max-width: 410px) {
  .side-content {
    .center-button-wrapper {
      width: auto;
      padding: 0 15px;
    }
  }
}

.content-header {
  color: #053c85;
  font-weight: 900;
  font-size: 25px;
}

.top-text-padded {
  padding-top: 42px;
}

p {
  line-height: 20px;
}

iframe {
  max-width: 100%;
}

$blackblue: #031125;
$grayblue: #35455a;
.content-text {
  &.ct-bold {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
  &.ct-thin {
    font-weight: 300;
  }
  &.ct-blackblue {
    color: $blackblue;
  }
  &.ct-left-padded {
    padding-left: 5%;
  }
  &.ct-right-padded {
    width: 85%;
  }
  &.ct-grayblue {
    color: $grayblue;
    line-height: 24px;
  }
  &.ct-justify {
    text-align: justify;
  }
  &.ct-large-top-margin {
    margin-top: 78px;
  }
}

.list-1 {
  color: #35455a;
  font-size: 14px;
  li {
    list-style: none;
    position: relative;
    line-height: 24px;
    margin-bottom: 14px;
    &:before {
      content: "•";
      font-size: 23px;
      color: #00a8ff;
      position: absolute;
      left: -23px;
      top: 0;
      line-height: 23px;
    }
  }
}

.button {
  display: inline-block;
  text-align: center;
  &.large-button {
    width: 100%;
    border: none;
    border-radius: 100px;
    font-size: 17px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    //padding: 20px 0;
    line-height: 70px;
  }
  &.yellow-button {
    background-color: #ffeb10;
    color: #000000;
  }
  &.blue-button {
    background-color: #00a8ff;
    color: #ffffff;
  }
}

#footer {
  &::before {
    height: 39px;
    content: '';
    display: block;
    background: url(/img/content/hr.png) no-repeat center center transparent;
    margin-top: -39px;
    position: absolute;
    width: 100%;
  }
  position: relative;
  color: #35455a;
  font-size: 12px;
  .phone {
    color: #00a8ff;
    font-size: 17px;
  }
  .uppper {
    text-transform: uppercase;
  }
  .copywr {
    color: #afbfd2;
  }
}

.content-section .footer-ico {
  padding-left: 25px;
  &.fi-pos {
    background: url('/img/ico-pos.png') no-repeat left center;
  }
  &.fi-mob {
    background: url('/img/ico-mob.png') no-repeat left center;
  }
}

.content-franchise {
  .banner-shadow{
  }
  .banner-yellow-footer{
    background: url(/img/banner-footer-yellow.png) no-repeat;
    width: 290px;
    margin: 0 auto;
    margin-top: -55px;
    position: relative;
    height: 154px;
    padding: 51px 10px;
    font-weight: 700;
    font-size: 19px;
  }
  .banner-yellow-wrapper{
    box-shadow: 0 0 15px rgba(118, 122, 125, 0.58);
    width: 290px;
    margin: 0 auto;
  }

  .content-img-advantages {
    margin-top: 50px;
    margin-bottom: 20px;
    max-width: 100%;
  }
  .content-img-header {
    //background-color: #fff;
    //position: absolute;
    //margin-top: -102px;
    //padding: 44px 112px 0;
  }

  .list-2 {
    color: #35455a;
    font-size: 14px;
    padding-left: 30px;
    margin-top: 21px;
    li {
      list-style: none;
      position: relative;
      line-height: 24px;
      margin-bottom: 14px;
      &:before {
        content: "";
        background: url("/img/content/list-2-icon.png") no-repeat;
        position: absolute;
        left: -29px;
        height: 9px;
        width: 12px;
        top: 8px;
      }
    }
  }

  .carousel-container {
    .carousel-title {
      color: #afbfd2;
      text-transform: uppercase;
      letter-spacing: 3px;
    }
    .carousel-title2 {
      color: #053c85;
      font-weight: 300;
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 34px;
    }
  }
}

.text-hr {
  height: 1px;
  width: 100%;
  background-color: #e2e2e2;
  position: relative;
  margin: 32px 0 40px;
  &:after {
    content: "";
    width: 100px;
    background-color: #00a8ff;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.title-category {
  color: #afbfd2;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 10px;
}

.side-content {
  .side-banner-1 {
    //      left: -27px;
    //      position: relative;
    width: 100%;
    margin-bottom: 50px;
  }
  .side-content-inner {
    .side-stats {
      .stat-number {
        color: #00a8ff;
        font-size: 40px;
        line-height: 42px;
      }
      .stat-description {
        color: #35455a;
        font-size: 14px;
        margin-top: 9px;
      }
    }
    .side-line-wrapper {
      padding: 47px 0 0 15px;
      .side-line {
        background-color: #e9ecef;
        height: 1px;
      }
    }
    .side-contacts {
      margin-top: 40px;
      margin-left: 16px;
      .contacts-item {
        padding-left: 50px;
        position: relative;
        + .contacts-item {
          margin-top: 30px;
        }
        .item-icon {
          position: absolute;
          color: #00a8ff;
          font-size: 20px;
          left: 0;
          top: 25px;
          &.fa-phone {
            margin-top: 6px;
          }
        }
        .item-label {
          color: #afbfd2;
        }
        .item-data {
          color: #35455a;
          &.data-phone {
            color: #053c85;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
    .button-apply {
      margin-top: 54px;
    }
  }
}

#map {
  height: 400px !important;
}

.btn-border-blue {
  border: 1px solid #00a8ff;
  color: #00a8ff;
  @include button-hovers() {
    color: #FFFFFF;
    background: #00a8ff;
    text-decoration: none;
  }
}

.map-container {
  margin: 100px 0 80px;
  position: relative;
  .map-logo {
    position: absolute;
    bottom: -20px;
    left: 100px;
  }
}

.carousel-container {
  .carousel-title {
    color: #afbfd2;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  .carousel-title2 {
    color: #053c85;
    font-weight: 300;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 34px;
  }
}

@media(max-width: 1199px) {
  .nav-top {
    line-height: 88px;
  }
}
@media(max-width: 767px) {
  .nav-top {
    display: none;
  }
  .nav-top-mobile {
    display: flex;
  }
  .content-text {
    &.ct-left-padded {
      padding-left: 0;
    }
  }
  .side-content {
    .side-banner-1 {
      left: 0;
    }
    .side-content-inner {
      .side-contacts {
        margin-left: 0;
      }
    }
  }
  .map-container .map-logo {
    display: none;
  }
}