// Body
$body-bg: #ffffff;
$font-size-default: 12px;
//fonts
$font-family-1: 'Open Sans', Arial, sans-serif;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$fa-font-path: "~font-awesome/fonts/";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
//$text-color: #636b6f;
$text-color: #000000;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

//WB
$nav-text-color: #212428;
$nav-label1-color: #afbfd2;
$nav-phone-color: #00a8ff;

$slider-text-color: #053c85;
$slider-description-color: #35455a;

// Durations
$fastDuration: .1s !default;
$mediumDuration: .3s !default;
$slowDuration: .5s !default;

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true !default;
$moz: false !default;
$ms: false !default;
$o: false !default;

@mixin prefixed($property, $value) {
  @if $webkit == true {
    -webkit-#{$property}: #{$value};
  }

  @if $moz == true {
    -moz-#{$property}: #{$value};
  }

  @if $ms == true {
    -ms-#{$property}: #{$value};
  }

  @if $o == true {
    -o-#{$property}: #{$value};
  }

  #{$property}: #{$value};
}

// prefix keyframes
@mixin keyframes($name) {
  @if $webkit == true {
    @-webkit-keyframes #{$name} {
      @content;
    }
  }

  @if $moz == true {
    @-moz-keyframes #{$name} {
      @content;
    }
  }

  @if $ms == true {
    @-ms-keyframes #{$name} {
      @content;
    }
  }

  @if $o == true {
    @-o-keyframes #{$name} {
      @content;
    }
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin hardwareAccel() {
  // Improve performance on mobile/tablet devices
  // Perspective reduces blurryness of text in Chrome
  @include prefixed(transform, perspective(1px) translateZ(0));
}

@mixin improveAntiAlias() {
  // Improve aliasing on mobile/tablet devices
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin forceBlockLevel() {
  // Transforms need to be block-level to work
  display: inline-block;
  vertical-align: middle;
}

@mixin hacks() {
  @include forceBlockLevel();
  @include hardwareAccel();
  @include improveAntiAlias();
}

@mixin back-pulse($name,$bg,$bg2) {
  @include keyframes(back-pulse-#{$name}) {
    50% {
      background-color: $bg2;
    }
  }
  .back-pulse-#{$name} {
    @include hacks();
    overflow: hidden;
    @include prefixed(transition-duration, $slowDuration);
    @include prefixed(transition-property, "color, background-color");

    &:hover,
    &:focus,
    &:active {
      @include prefixed(animation-name, back-pulse-#{$name});
      @include prefixed(animation-duration, 1s);
      @include prefixed(animation-delay, $slowDuration);
      @include prefixed(animation-timing-function, linear);
      @include prefixed(animation-iteration-count, infinite);
      background-color: $bg;
      @content;
    }
  }
}

@include back-pulse('blue', #00a8ff, darken(#00a8ff, 10%)) {
  color: #ffffff;
  text-decoration: none;
}

@include back-pulse('yellow', #ffeb10, #ffd601) {
  color: #000000;
  text-decoration: none;
}

// custom

@mixin link-style1() {
  font-size: 1em;
  text-transform: uppercase;
  color: $nav-text-color;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0 14px;
  font-weight: 800;
}

@mixin button-rounded() {
  border-radius: 20px;
}

@mixin button-colors($bg,$color) {
  border: 1px solid $bg;
  background: $bg;
  color: $color;
}

@mixin button-sizes($padding,$height) {
  display: inline-block;
  text-transform: uppercase;
  padding: 0 $padding;
  line-height: $height;
  font-size: 1em;
  font-weight: 600;
  @content;
}

@mixin button-hovers() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

.bs-wizard {
  //colors
  $color_0: #e0e0e0;
  $color_1: #595959;
  $color_2: #999;
  $color_3: #fbe8aa;
  $color_4: #fbbd19;
  $color_5: #f5f5f5;
  margin-top: 40px;
  border-bottom: solid 1px $color_0;
  padding: 0 0 10px 0;
  > .bs-wizard-step {
    padding: 0;
    position: relative;
    .bs-wizard-stepnum {
      color: $color_1;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .bs-wizard-info {
      color: $color_2;
      font-size: 14px;
    }
    > {
      .bs-wizard-dot {
        position: absolute;
        width: 30px;
        height: 30px;
        display: block;
        background: $color_3;
        top: 45px;
        left: 50%;
        margin-top: -15px;
        margin-left: -15px;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 50%;
        &:after {
          content: ' ';
          width: 14px;
          height: 14px;
          background: $color_4;
          //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
          border-radius: 50px;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }
      .progress {
        position: relative;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 0;
        height: 8px;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: none;
        margin: 20px 0;
        > .progress-bar {
          width: 0;
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
          background: $color_3;
        }
      }
    }
    &:first-child {
      &.active > .progress > .progress-bar {
        width: 0;
      }
      > .progress {
        left: 50%;
        width: 50%;
      }
    }
    &:last-child {
      &.active > .progress > .progress-bar {
        width: 100%;
      }
      > .progress {
        width: 50%;
      }
    }
    &.disabled {
      > .bs-wizard-dot {
        background-color: $color_5;
        &:after {
          opacity: 0;
        }
      }
      a.bs-wizard-dot {
        pointer-events: none;
      }
    }
    + .bs-wizard-step {
    }
    &.complete > .progress > .progress-bar {
      width: 100%;
    }
    &.active > .progress > .progress-bar {
      width: 50%;
    }
  }
}